import React, { useState } from 'react';
import {
  ColumnSort,
  DataTable,
  DataTableColumn,
  TextButton,
} from '@allergan-data-labs/alle-elements';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatNumberWithCommas } from '@allergan-data-labs/universal-component-library/src/helpers/format';
import { OfferStatusBadge } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/offerStatusBadge/offerStatusBadge';
import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronRightIcon';
import { BaseComponentProps } from '../../common';
import { getSortedItems } from '../../../utils';
import { ContextMenuCell, MenuActionType } from '../cells';
import { Offer } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/types';
import { OfferStatus } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/types';
import { format } from 'date-fns';

export interface OfferBuilderTableProps extends BaseComponentProps {
  data: Array<Offer>;
  isLoading?: boolean;
  onInternalNameClick: (internalName: string) => void;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
}

const OfferBuilderRepDispensedFlashTable: React.FC<OfferBuilderTableProps> = ({
  data,
  dataTestId = 'rep-dispensed-flash-table',
  isLoading = false,
  onInternalNameClick,
  onActionItemClick,
}) => {
  const columns: Array<DataTableColumn<Offer>> = [
    {
      column: 'Offer Name',
      field: 'name',
      renderCell: (offer, name) => {
        return (
          <Flex flexDirection="column" minWidth="400px">
            <TextButton
              variant="ghost"
              colorScheme="brand"
              onClick={() => onInternalNameClick(offer.id)}
              rightIcon={<ChevronRightIcon />}
              justifyContent="flex-start"
            >
              <Text textStyle="Body/Medium/Regular" as="span" isTruncated>
                {name as string}
              </Text>
            </TextButton>
          </Flex>
        );
      },
    },
    {
      column: 'Status',
      field: 'status',
      renderCell: (_, status) => {
        return (
          <Box
            width="98px"
            display="flex"
            justifyContent="flex-start"
            isTruncated
          >
            <OfferStatusBadge
              status={status as OfferStatus}
              pendingChangeRequests={false}
            />
          </Box>
        );
      },
    },
    {
      column: 'Start Date',
      field: 'startDate',
      renderCell: (offer) => {
        const dateFormatted =
          format(offer.startDate, 'MM.dd.yy hh:mm a') || 'n/d';
        return (
          <Box maxWidth="135px">
            <Text
              textStyle="Body/Medium/Regular"
              as="span"
              isTruncated
              width="135px"
              display="block"
            >
              {dateFormatted}
            </Text>
          </Box>
        );
      },
    },
    {
      column: 'Total Value',
      field: 'value',
      renderCell: (_, value) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            width="100%"
            paddingRight="20px"
          >
            ${formatNumberWithCommas(value as string)}
          </Text>
        );
      },
    },
    {
      column: 'Total Quantity',
      field: 'quantity',
      renderCell: (_, value) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            width="100%"
            paddingRight="20px"
            align="right"
          >
            {formatNumberWithCommas(value as string)}
          </Text>
        );
      },
    },
    {
      column: 'In Patient Wallet',
      field: 'scanned',
      renderCell: (_, redeemed) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="20px"
          >
            {formatNumberWithCommas(redeemed as string)}
          </Text>
        );
      },
    },
    {
      column: 'Patient Redeemed',
      field: 'redeemed',
      renderCell: (_, redeemed) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="20px"
          >
            {formatNumberWithCommas(redeemed as string)}
          </Text>
        );
      },
    },
    {
      column: 'Available in Flash',
      field: 'remaining',
      renderCell: (_, remaining) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="20px"
          >
            {formatNumberWithCommas(remaining as string)}
          </Text>
        );
      },
    },
    {
      column: 'Available to Allocate',
      field: 'allocated',
      renderCell: (_, allocated) => {
        return (
          <>
            <Text
              textStyle="Body/Medium/Regular"
              as="span"
              align="right"
              width="100%"
              paddingRight="20px"
            >
              {formatNumberWithCommas(allocated as string)}
            </Text>
          </>
        );
      },
    },
    {
      column: '',
      field: 'menu',
      renderCell: (offer) => {
        return (
          <>
            <ContextMenuCell
              flexGrow={1}
              offer={offer as any}
              onActionItemClick={onActionItemClick}
              data-testid={`user-${(offer as any).id}-context-menu`}
            />
          </>
        );
      },
    },
  ];

  const [sortConfig, setSortConfig] = useState<ColumnSort>({
    id: null,
    direction: 'asc',
  });

  const handleSort = (sortingState: ColumnSort) => {
    setSortConfig(sortingState);
  };

  return (
    <Box
      sx={{
        'th:first-child button': {
          display: 'none',
        },
        'th:last-child div': {
          display: 'none',
        },
      }}
    >
      <DataTable
        isLoading={isLoading}
        data-testid={dataTestId}
        columns={columns}
        rowGap={8}
        data={getSortedItems(sortConfig, data)}
        onSort={handleSort}
        variant="closed"
        sx={{
          '& tbody tr': { cursor: 'initial !important' },
        }}
      />
    </Box>
  );
};

export { OfferBuilderRepDispensedFlashTable };
